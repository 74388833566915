/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
section.section--cta {
  background-color: #242830;
}
section.section--cta figure {
  min-height: 13.3125rem;
}
@media screen and (min-width:480px) {
  section.section--cta figure {
    min-height: 34rem;
  }
}

.qpac.cta {
  overflow: hidden;
  padding: 2rem 0 0;
}
.qpac.cta h2 {
  max-width: 80%;
  margin-bottom: 2rem;
  color: #00FFAE;
  font-size: clamp(1.5rem, 3.00752vw + 0.793233125rem, 3.5rem);
  font-weight: 400;
  letter-spacing: -0.015rem;
  line-height: 100%;
  text-transform: uppercase;
}
@media screen and (min-width:640px) {
  .qpac.cta h2 {
    letter-spacing: -0.035rem;
  }
}
.qpac.cta article {
  max-width: 50.4375rem;
  margin-bottom: clamp(2.5rem, 5.26316vw + 1.263158125rem, 6rem);
}
.qpac.cta .l-btn {
  width: 100%;
  max-width: 13.75rem;
  padding: 0.90625rem 1rem 0.96875rem;
}
.qpac.cta .l-btn::before {
  background-color: #242830;
}
.qpac.cta figure.aspect-box {
  --aspect-ratio: 213 / 343;
}
@media screen and (min-width:480px) {
  .qpac.cta figure.aspect-box {
    --aspect-ratio: 544 / 1400;
  }
}
.qpac.cta figure img {
  width: 34.0625rem;
  max-width: unset;
}
@media screen and (min-width:480px) {
  .qpac.cta figure img {
    width: 100%;
    max-width: 100%;
  }
}